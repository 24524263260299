<template>
  <div class="home">
    <Bar>
      <el-row :gutter="20">
        <el-col :span="16">
          <div class="search">
            <el-input
              v-model="queryParams.params.customerPhone"
              placeholder="请输入搜索关键字"
              @keyup.enter.native="search"
            >
              <i
                slot="prefix"
                class="el-input__icon el-icon-search search-btn"
                @click.stop="search"
              />
            </el-input>
          </div>
          <!-- <el-button
            round
            class="add-btn linear-btn"
            @click="$router.push('/addUser')"
          >
            <i class="el-icon-circle-plus-outline add-icon" />
            新建测评用户
          </el-button> -->
          <span class="label select-linear">
            <span>状态:</span>
            <el-select
              v-model="queryParams.params.status"
              placeholder="请选择"
              @change="search"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </span>
        </el-col>
        <el-col
          :span="8"
          class="text-right"
        >
          <!-- <el-popconfirm
            title="确定要删除吗？"
            @confirm="deleteUser(multipleSelection)"
          >
            <el-button
              slot="reference"
              round
              class="linear-btn"
              :disabled="!multipleSelection.length"
            >
              删除
            </el-button>
          </el-popconfirm> -->
        </el-col>
      </el-row>
    </Bar>
    <div class="panel">
      <el-table
        ref="outTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @expand-change="expandChange"
        @row-click="tableRowClick"
      >
        <el-table-column
          type="expand"
        >
          <div
            slot-scope="scope"
            class="report-box"
          >
            <!-- v-if="/2/.test(scope.row.customerStatus)" -->
            <el-table
              :data="scope.row.list"
              style="width: 100%"
            >
              <el-table-column
                label="名称"
                width="200"
              >
                <!-- <el-button
                  slot-scope="props"
                  type="text"
                  size="small"
                  @click="$router.push({
                    name: 'eval.report',
                    query:{
                      id: scope.row.id,
                      evalRecordId: props.row.id,
                      childrenSex: scope.row.childrenSex,
                      checkModal: true
                    }
                  })" -->
                >
                <!-- </el-button> -->
                <template slot-scope="props">
                  报告{{ ++props.$index }}
                </template>
              </el-table-column>
              <el-table-column
                prop="height"
                label="身高"
                width="200"
                :formatter="(row, column, cellValue)=>`${cellValue||'-'}cm`"
              />
              <el-table-column
                prop="weight"
                label="体重"
                width="200"
                :formatter="(row, column, cellValue)=>`${cellValue||'-'}kg`"
              />
              <el-table-column
                prop="submitTime"
                width="250"
                label="测评时间"
              />
              <el-table-column label="操作">
                <template slot-scope="props">
                  <el-button
                    type="text"
                    size="small"
                    @click="$router.push({
                      name: 'eval.report',
                      query:{
                        id: scope.row.id,
                        evalRecordId: props.row.id,
                        childrenSex: scope.row.childrenSex,
                        checkModal: true
                      }
                    })"
                  >
                    查看报告
                  </el-button>
                  <el-button
                    v-if="props.row.expertReportState==='NOT_APPLIED'&&props.$index===0"
                    type="text"
                    size="small"
                    @click="applyExpertReport(props.row)"
                  >
                    申请专家报告
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-table-column>
        <el-table-column
          type="selection"
          width="50"
          align="center"
        />
        <el-table-column
          label="序号"
          width="80"
          prop="index"
          sortable
        />
        <el-table-column
          prop="childrenName"
          label="测评宝宝"
          width="150"
          sortable
        />
        <el-table-column
          prop="customerPhone"
          label="监护人电话"
          align="center"
          width="150"
          sortable
        />
        <el-table-column
          label="已测评数/总测评数"
          align="center"
          width="150"
          prop="numDescription"
        />
        <el-table-column
          align="center"
          width="180"
          label="更新时间"
          prop="updateTime"
          sortable
        >
          <template slot-scope="scope">
            {{ dateFormat('YYYY-mm-dd HH:MM',scope.row.updateTime) }}
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          prop="status"
          align="center"
          width="150"
        >
          <div
            slot-scope="scope"
            class="status"
          >
            <span
              class="status-circle"
              :style="`background:${getStatus(scope.row.status).color}`"
            />
            <span class="status-text">{{ scope.row.status ||'-' }}</span>
          </div>
        </el-table-column>
        <!-- <el-table-column
          prop="customerStatus"
          label="状态"
          align="center"
          width="150"
          show-overflow-tooltip
        >
          <div
            slot-scope="scope"
            class="status"
          >
            <span
              class="status-circle"
              :style="`background:${getStatus(scope.row.customerStatus).color}`"
            />
            <span class="status-text">{{ getStatus(scope.row.customerStatus).label }}</span>
          </div>
        </el-table-column> -->
        <el-table-column
          label="操作"
        >
          <template slot-scope="scope">
            <!-- v-if="/1|0/.test(scope.row.customerStatus)" -->
            <el-button
              v-if="canEval(scope)"
              type="text"
              size="small"
              @click.stop="$router.push({
                name: 'eval.editChildrenInfo',
                query: { id: scope.row.id }
              })"
            >
              开始测评
            </el-button>
            <el-button
              type="text"
              size="small"
              @click.stop="$router.push({
                path: `/editUser/${scope.row.id}`,
                query: {disableEdit: true}
              })"
            >
              宝宝信息
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination text-right">
        <el-pagination
          background
          layout="prev, pager, next"
          hide-on-single-page
          :current-page="queryParams.current"
          :page-size="queryParams.size"
          :total="pageTotal"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>

    <QrLayer />
  </div>
</template>

<script>
import {
  getCustomerList, deleteUser, childEvalRecord, applyExpertReport
} from '@/api';
import { dateFormat } from '@/utils/utils';

export default {
  name: 'Home',
  components: {
    QrLayer: () => import('../home/qrLayer.vue')
  },
  data() {
    return {
      // 测评状态 0:未测评; 1:已测评 2:已完成
      options: [
        { label: '全部', value: undefined, color: '#666' },
        { label: '待测评', value: 0, color: '#FFA14A' },
        { label: '已测评', value: 1, color: '#87D068' },
        { label: '已完成', value: 2, color: '#78DBD9' },
      ],
      tableData: [],
      multipleSelection: [],
      queryParams: {
        params: {
          customerPhone: '',
          status: undefined
        },
        current: 1,
        size: 10
      },
      pageTotal: 0,
      childData: []
    };
  },
  created() {
    this.init();
  },
  methods: {
    dateFormat,
    init() {
      this.search();
    },
    search() {
      this.queryParams.current = 1;
      this.getCustomerList();
    },
    // 查询评测用户列表
    getCustomerList() {
      getCustomerList(this.queryParams).then((res) => {
        this.tableData = (res?.data?.records || []).map((item, index) => {
          const dataIndex = (this.queryParams.current - 1) * 10 + index + 1;
          return {
            ...item,
            index: dataIndex,
            list: []
          };
        });

        this.pageTotal = res.data?.total || 0;
      });
    },
    // table勾选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页选择
    handleCurrentChange(val) {
      this.queryParams.current = val;
      this.getCustomerList();
    },
    // 测评用户状态
    getStatus(status) {
      // 0--待激活 1--已激活 2--已测评
      const cur = this.options.find((item) => item.label === status);
      return cur || { color: '#666' };
    },
    // 删除用户
    deleteUser(item) {
      const params = {
        id: item.id
      };
      deleteUser(params).then((res) => {
        this.$message.warning(res.msg);
        setTimeout(() => {
          this.getCustomerList();
        }, 1500);
      }, (err) => {
        this.$message.warning(err.msg);
      });
    },
    // 激活用户
    activeUser() {
      this.$message.success('用户激活成功！');
    },
    async expandChange(row = {}, expandedRows = []) {
      const isExpand = expandedRows.some((item) => item.id === row.id);
      if (!isExpand || row.list?.length) return;
      const list = await this.childEvalRecord(row);
      this.$set(row, 'list', list);
    },
    tableRowClick(row, index, e) {
      this.$refs.outTable.toggleRowExpansion(row);
    },
    childEvalRecord(row) {
      const params = {
        id: row.id
      };
      return childEvalRecord(params).then((res) => res?.data || [], ((err) => []));
    },
    // 申请专家测评报告
    applyExpertReport(item) {
      const params = {
        evalRecordId: item.id
      };
      applyExpertReport(params).then((res) => {
        this.$message.success(res.msg || '申请成功！');
        setTimeout(() => {
          this.getCustomerList();
        }, 1000);
      });
    },
    canEval(scope) {
      const [first, second] = (scope.row.numDescription || '').split('/');
      return Number(first) < Number(second);
    }
  },
};
</script>
<style lang="less">
.el-button+.el-button{
  margin-left: 0;
}
.el-button{
  &:not(:last-child){
    margin-right: 10px;
  }
}
</style>

<style lang="less" scoped>
.search{
  width: 306px;
  display: inline-block;
  margin-right: 12px;
  &-btn{
    color: #000;
    font-size: 20px;
    line-height: 42px;
    padding: 0 5px;
    font-weight: 600;
    cursor: pointer;
  }
  /deep/ .el-input__inner{
    border-radius: 40px;
    padding-left: 41px;
    font-size: 16px;
    color: #000;
    &:focus{
      border-color: #85C6CE;
    }
  }
}
.label{
  font-size: 16px;
  margin-left: 20px;
}
.add-btn{
  padding-left: 10px;
  padding-right: 10px;
}
.add-icon{
  font-size: 20px;
  vertical-align: middle;
}
.panel{
  margin-top: 16px;
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 16px;
  /deep/.el-table {
    color:#000;
    thead .cell{
      color: #000;
      font-weight: 400;
    }
  }
  .status{
    padding-left: 26px;
    position: relative;
    display: inline-block;
    &-circle{
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      margin-top: -6px;
      height: 12px;
      width: 12px;
      border-radius: 12px;
      background: #333;
    }
  }
  .pagination{
    margin-top: 16px;
  }
  .report-box{
    background: #f8f8f8;
    padding: 0 50px 0 100px;
    /deep/.el-table,/deep/.el-table th, /deep/.el-table tr,/deep/.el-table__expanded-cell{
      background: #f8f8f8;
    }
  }
  /deep/.el-table__expanded-cell[class*="cell"]{
    padding: 0;
  }
}
</style>
